<template>
  <div id="address-form">
    <b-row>
      <b-col cols="4">
        <div class="form-group">
          <label for="CEP">CEP</label>
          <validation-provider
            name="CEP"
            rules="required"
            v-slot="{ errors, touched, dirty }"
          >
            <input
              autocomplete="off"
              id="CEP"
              type="text"
              v-model="address.zipCode"
              class="form-control"
              v-mask="'#####-###'"
              @keyup="searchCEP"
              :class="{
                error: errors.length > 0 && dirty
              }"
            />
            <span
              class="error-message"
              v-if="(touched && errors.length > 0) || invalidForm"
            >
              {{ errors[0] }}
            </span>
          </validation-provider>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="form-group">
          <label for="rua">Rua</label>
          <validation-provider
            name="rua"
            rules="required"
            v-slot="{ errors, touched, dirty }"
          >
            <input
              autocomplete="off"
              id="rua"
              type="text"
              v-model="address.address"
              class="form-control"
              :class="{
                error: errors.length > 0 && dirty
              }"
            />
            <span
              class="error-message"
              v-if="(touched && errors.length > 0) || invalidForm"
            >
              {{ errors[0] }}
            </span>
          </validation-provider>
        </div>
      </b-col>
      <b-col cols="2">
        <div class="form-group">
          <label for="número">Nº</label>
          <validation-provider
            name="número"
            rules="required"
            v-slot="{ errors, touched, dirty }"
          >
            <input
              autocomplete="off"
              id="número"
              ref="addressNumber"
              type="text"
              v-model="address.number"
              class="form-control"
              :class="{
                error: errors.length > 0 && dirty
              }"
            />
            <span
              class="error-message"
              v-if="(touched && errors.length > 0) || invalidForm"
            >
              {{ errors[0] }}
            </span>
          </validation-provider>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="3">
        <div class="form-group">
          <label for="complement"
            >Complemento <span class="help">(opcional)</span></label
          >
          <input
            autocomplete="off"
            id="complement"
            type="text"
            v-model="address.complement"
            class="form-control"
          />
        </div>
      </b-col>
      <b-col cols="3">
        <div class="form-group">
          <label for="bairro">Bairro</label>
          <validation-provider
            name="bairro"
            rules="required"
            v-slot="{ errors, touched, dirty }"
          >
            <input
              autocomplete="off"
              id="bairro"
              type="text"
              v-model="address.neighborhood"
              class="form-control"
              :class="{
                error: errors.length > 0 && dirty
              }"
            />
            <span
              class="error-message"
              v-if="(touched && errors.length > 0) || invalidForm"
            >
              {{ errors[0] }}
            </span>
          </validation-provider>
        </div>
      </b-col>
      <b-col cols="4">
        <div class="form-group">
          <label for="city">Cidade</label>
          <validation-provider
            name="cidade"
            rules="required"
            v-slot="{ errors, touched, dirty }"
          >
            <input
              autocomplete="off"
              id="city"
              type="text"
              v-model="address.city"
              class="form-control"
              :class="{
                error: errors.length > 0 && dirty
              }"
            />
            <span
              class="error-message"
              v-if="(touched && errors.length > 0) || invalidForm"
            >
              {{ errors[0] }}
            </span>
          </validation-provider>
        </div>
      </b-col>
      <b-col cols="2">
        <div class="form-group">
          <label for="estado">Estado</label>
          <validation-provider
            name="estado"
            rules="required"
            v-slot="{ errors, touched, dirty }"
          >
            <multiselect
              id="estado"
              v-model="address.state"
              track-by="id"
              label="acronym"
              :options="statesOptions"
              selectedAddressStateabel="name"
              :option-height="40"
              :showLabels="false"
              :searchable="false"
              :allowEmpty="false"
              placeholder="UF"
              class="with-border"
              :class="{
                error: errors.length > 0 && dirty
              }"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions"> Nenhuma opção </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <span
              class="error-message"
              v-if="(touched && errors.length > 0) || invalidForm"
            >
              {{ errors[0] }}
            </span>
          </validation-provider>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import * as cep from 'cep-promise'
export default {
  name: 'AddressForm',
  props: {
    patient: Object,
    invalidForm: Boolean
  },
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg')
  },
  data() {
    return {
      address: this.getDefaultForm(),
      statesOptions: []
    }
  },
  mounted (){
    this.getStates()    
  },
  watch: {
    patient: {
      deep: true,
      handler() {
        this.address.zipCode = this.patient?.address?.zipCode ?? null
        this.address.address = this.patient?.address?.address ?? null
        this.address.number = this.patient?.address?.number ?? null
        this.address.complement = this.patient?.address?.complement ?? null
        this.address.neighborhood = this.patient?.address?.neighborhood ?? null
        this.address.city = this.patient?.address?.city ?? null
        this.address.state = this.patient?.address?.state ?? null
        this.selectedAddressState = this.patient?.address?.state ?? null
      }
    },
    address: {
      deep: true,
      handler() {
        this.$emit('updateAddress', this.address)
      }
    }
  },
  methods: {
    getDefaultForm() {
      return {
        selectedAddressState: null,
        zipCode: null,
        address: null,
        number: null,
        complement: null,
        neighborhood: null,
        city: null,
        state: null
      }
    },
    searchCEP() {
      if (this.address.zipCode.length === 9) {
        cep(this.address.zipCode)
          .then(res => {
            this.address.address = res.street
            this.address.neighborhood = res.neighborhood
            this.address.city = res.city
            this.address.state = this.statesOptions.find(item => item.acronym === res.state);
            this.selectedAddressState = this.statesOptions.find(item => item.acronym === res.state);

            this.$refs.addressNumber.focus()
          })
          .catch(err => {
            console.log('Err', err)
          })
      }
    },
    getStates() {
      if (this.statesOptions.length === 0) {
        this.api.getStates().then(async res => {
          this.statesOptions = res.data.map(type => {
            return {
              label: `${type?.code_ibge} - ${type.name}`,
              code: type.code_ibge,
              acronym: type.acronym,
              id: type.id
            }
          })
        })
      }
    },
  }
}
</script>
